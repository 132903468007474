'use client'

import React, { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import { LoadingBar } from '../LoadingBar'
import { EntityResponse, OmniBoxResults } from './OmniBox.types'
import { AccountListItem, ContactListItem, LeadListItem, OpportunityListItem, ResultsList, SiteListItem } from './OmniBoxResultItems'
import styles from './OmniBoxResults.module.scss'

interface OmniBoxResultsProps {
  searchBoxRef?: React.RefObject<HTMLInputElement>
  loading?: boolean
  results?: OmniBoxResults
  recentSearches?: EntityResponse[]
  reset?: (term?: EntityResponse) => void
  searchTerm?: string
}

export const OmniBoxResultsBox = forwardRef(function OmniBoxResultsBox(props: OmniBoxResultsProps, ref: any) {
  const { loading, searchBoxRef, results, recentSearches, reset, searchTerm } = props
  const header = document.getElementsByTagName('header')[0]
  const [showRecent, setShowRecent] = useState(false)
  const [filter, setFilter] = useState('all')

  useEffect(() => {
    setShowRecent(recentSearches.length > 0)
  }, [recentSearches])

  useEffect(() => {
    if (header) header.style.overflow = 'visible'
    document.body.style.overflow = 'hidden'

    return () => {
      if (header) header.style.overflow = 'hidden'
      document.body.style.overflow = 'visible'
    }
  }, [header])

  const handleKeyDown = (e: React.KeyboardEvent, search: string = null) => {
    if (e.key === 'ArrowDown') {
      const parent = e.currentTarget.parentElement as HTMLElement
      const nextSibling = parent.nextSibling as HTMLElement
      if (nextSibling) {
        nextSibling.querySelector('a')?.focus()
      } else {
        searchBoxRef.current.focus()
      }
    } else if (e.key === 'ArrowUp') {
      const parent = e.currentTarget.parentElement as HTMLElement
      const previousSibling = parent.previousSibling as HTMLElement
      const previousSiblingHasA = previousSibling?.querySelector('a')
      if (previousSibling && previousSiblingHasA) {
        previousSibling.querySelector('a')?.focus()
      } else {
        searchBoxRef.current.focus()
      }
    } else if (e.key === 'Enter' || e.key === ' ') {
      //reset(search)
      ;(e.currentTarget as any).click()
    } else if (e.key === 'Escape') {
      reset()
    }
  }

  //stupid next hack
  const Transition = CSSTransition as any

  const fadeClassNames = {
    enter: styles['fade-enter'],
    enterActive: styles['fade-enter-active'],
    exit: styles['fade-exit'],
    exitActive: styles['fade-exit-active'],
  }
  const slideClassNames = {
    enter: styles['slide-enter'],
    enterActive: styles['slide-enter-active'],
    exit: styles['slide-exit'],
    exitActive: styles['slide-exit-active'],
  }

  const animationMS = 250

  const FilterButton = ({ filterName }: { filterName: string }) => (
    <button
      className={classNames('capitalize', styles['filter-button'], {
        '!bg-primary !text-white': filter === filterName,
        [styles.hidden]: filterName !== 'all' && results?.[filterName as keyof OmniBoxResults]?.length == 0,
      })}
      onClick={() => setFilter(filterName)}
    >
      {filterName}
    </button>
  )

  return (
    <div
      className={classNames(
        'absolute w-full max-w-[480px] overflow-hidden rounded-[16px] border border-gray-200 bg-white text-sm shadow-lg',
        styles.results,
      )}
      ref={ref}
    >
      <div className={styles['results-inner']}>
        <Transition in={loading} timeout={animationMS} classNames={{ ...slideClassNames }} unmountOnExit>
          <LoadingBar style={{ margin: '-12px -12px 100px' }} />
        </Transition>

        <Transition in={showRecent && searchTerm?.length < 3} timeout={animationMS} classNames={{ ...slideClassNames }} unmountOnExit>
          <div>
            <h3 className="p-lg pb-sm font-bold text-gray-800">Recently Visited</h3>
            <ResultsList>
              {recentSearches.map((entity) => {
                if ('fullName' in entity) {
                  if ('currentRole' in entity) {
                    return <ContactListItem key={entity.id} entity={entity} reset={reset} handleKeyDown={handleKeyDown} />
                  } else {
                    return <LeadListItem key={entity.id} entity={entity} reset={reset} handleKeyDown={handleKeyDown} />
                  }
                } else if ('owner' in entity) {
                  return <SiteListItem key={entity.id} entity={entity} reset={reset} handleKeyDown={handleKeyDown} />
                } else if ('name' in entity && 'bidDueDate' in entity) {
                  return <OpportunityListItem key={entity.id} entity={entity} reset={reset} handleKeyDown={handleKeyDown} />
                } else if ('name' in entity && 'taxID' in entity) {
                  return <AccountListItem key={entity.id} entity={entity} reset={reset} handleKeyDown={handleKeyDown} />
                }
                return <></>
              })}
            </ResultsList>
          </div>
          {/* <X className="cursor-pointer" width={20} height={20} onClick={() => setRecentSearches([])} /> */}
        </Transition>

        <Transition in={results && !loading} timeout={animationMS} classNames={{ ...slideClassNames }} unmountOnExit>
          <div className={styles['filter']}>
            <div>
              {['all', 'leads', 'opportunities', 'contacts', 'accounts', 'sites'].map(
                (filterName) => true && <FilterButton key={filterName} filterName={filterName} />,
              )}
            </div>
          </div>
        </Transition>

        <Transition
          in={results && results?.leads.length > 0 && (filter === 'all' || filter === 'leads')}
          timeout={animationMS}
          classNames={{ ...slideClassNames }}
          unmountOnExit
        >
          <ResultsList>
            {results?.leads.slice(0, filter === 'all' ? 3 : 10).map((lead) => (
              <LeadListItem key={lead.id} entity={lead} reset={reset} handleKeyDown={handleKeyDown} filtered={filter === 'leads'} />
            ))}
          </ResultsList>
        </Transition>
        <Transition
          in={results && results?.opportunities.length > 0 && (filter === 'all' || filter === 'opportunities')}
          timeout={animationMS}
          classNames={{ ...slideClassNames }}
          unmountOnExit
        >
          <ResultsList>
            {results?.opportunities.slice(0, filter === 'all' ? 3 : 10).map((opportunity) => (
              <OpportunityListItem
                key={opportunity.id}
                entity={opportunity}
                reset={reset}
                handleKeyDown={handleKeyDown}
                filtered={filter === 'opportunities'}
              />
            ))}
          </ResultsList>
        </Transition>
        <Transition
          in={results && results?.contacts.length > 0 && (filter === 'all' || filter === 'contacts')}
          timeout={animationMS}
          classNames={{ ...slideClassNames }}
          unmountOnExit
        >
          <ResultsList>
            {results?.contacts.slice(0, filter === 'all' ? 3 : 10).map((contact) => (
              <ContactListItem key={contact.id} entity={contact} reset={reset} handleKeyDown={handleKeyDown} filtered={filter === 'contacts'} />
            ))}
          </ResultsList>
        </Transition>
        <Transition
          in={results && results?.accounts.length > 0 && (filter === 'all' || filter === 'accounts')}
          timeout={animationMS}
          classNames={{ ...slideClassNames }}
          unmountOnExit
        >
          <ResultsList>
            {results?.accounts.slice(0, filter === 'all' ? 3 : 10).map((account) => (
              <AccountListItem key={account.id} entity={account} reset={reset} handleKeyDown={handleKeyDown} filtered={filter === 'accounts'} />
            ))}
          </ResultsList>
        </Transition>
        <Transition
          in={results && results?.sites.length > 0 && (filter === 'all' || filter === 'sites')}
          timeout={animationMS}
          classNames={{ ...slideClassNames }}
          unmountOnExit
        >
          <ResultsList>
            {results?.sites.slice(0, filter === 'all' ? 3 : 10).map((site) => (
              <SiteListItem key={site.id} entity={site} reset={reset} handleKeyDown={handleKeyDown} filtered={filter === 'sites'} />
            ))}
          </ResultsList>
        </Transition>
      </div>
    </div>
  )
})
